<template>
  <v-card>
    <v-card-title>Send an invitation</v-card-title>
    <form
      class="pa-4"
      @submit.prevent="sendInvitation"
    >
      <v-text-field
        type="email"
        label="Email"
        v-model="invitationForm.email"
      />
      <v-btn
        color="primary"
        type="submit"
      >
        Send
      </v-btn>
    </form>
  </v-card>
</template>

<script>
import { findoutTheErrorMsg } from "../../utils/error-parser";

export default {
  name: "SendInvitation",
  data() {
    return {
      invitationForm: {},
    };
  },
  methods: {
    async sendInvitation() {
      const response = await this.$store.dispatch("sendInvitationAction", {
        email: this.invitationForm.email,
      });
      if (response && response.status === 201) {
        this.invitationForm = {};
        this.$ebus.$emit("newToastMessage", {
          message: "Invitation will be sent over the email soon.",
        });
      } else {
        this.$ebus.$emit("newToastMessage", {
          message: findoutTheErrorMsg(response),
        });
      }
    },
  },
};
</script>