<template>
  <div>
    <invitation-wrapper />
  </div>
</template>

<script>
import InvitationWrapper from "../../components/invitation/InvitationWrapper.vue";
export default {
  components: { InvitationWrapper },
  name: "InvitationRequestListView",
};
</script>