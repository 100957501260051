<template>
  <v-list>
    <v-list-item
      v-for="request in requests"
      :key="request.id"
    >
      <v-list-item-avatar>
        <v-icon
          class="grey lighten-1"
          dark
        >
          mdi-mail
        </v-icon>
      </v-list-item-avatar>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-content
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-title>{{ request.email }}</v-list-item-title>
            <v-list-item-subtitle v-html="request.message" />
          </v-list-item-content>
        </template>
        <span>{{ request.message }}</span>
      </v-tooltip>
      <v-list-item-action>
        <v-btn
          small
          color="primary"
          @click="onStatusUpdate(request, 'sent')"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-list-item-action>

      <v-list-item-action>
        <v-btn
          small
          color="red white--text"
          @click="onStatusUpdate(request, 'rejected')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { findoutTheErrorMsg } from "../../utils/error-parser";

export default {
  name: "InvitationRequestList",
  props: {
    requests: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async onStatusUpdate(request, status) {
      if (status === "sent") {
        const response = await this.$store.dispatch("sendInvitationAction", {
          email: request.email,
        });
        if (response && response.status === 201) {
          await this.$store.dispatch("updateRequestInvitationStatusAction", {
            id: request.id,
            status: status,
          });
          this.$ebus.$emit("newToastMessage", {
            message: "Invitation accepted. Will be send over the email soon.",
          });
        } else {
          this.$ebus.$emit("newToastMessage", {
            message: findoutTheErrorMsg(response),
          });
        }
      } else {
        const response = await this.$store.dispatch(
          "updateRequestInvitationStatusAction",
          {
            id: request.id,
            status: status,
          }
        );
        if (response && response.status === 200) {
          this.$ebus.$emit("newToastMessage", {
            message: "Invitation rejected.",
          });
        } else {
          this.$ebus.$emit("newToastMessage", {
            message: findoutTheErrorMsg(response),
          });
        }
      }
    },
  },
};
</script>