<template>
  <v-row dense>
    <v-col
      cols="12"
      md="8"
      sm="6"
    >
      <p class="title pl-4">
        Pending Invitation Requests
      </p>

      <invitation-request-list :requests="allInvitationRequests" />
      <p
        class="subtitle text-center"
        v-if="allInvitationRequests.length === 0"
      >
        No pending invitation for you.
      </p>
      <v-btn
        block
        color="orange"
        @click="pendingInvitationLoadMore"
        v-if="pendingInvitationPagination.next"
      >
        Load More
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <send-invitation />
    </v-col>
  </v-row>
</template>

<script>
import InvitationRequestList from "../../components/invitation/InvitationRequestList.vue";
import { mapGetters } from "vuex";
import SendInvitation from "../../components/invitation/SendInvitation.vue";

export default {
  components: { InvitationRequestList, SendInvitation },
  name: "InvitationWrapper",
  computed: {
    ...mapGetters(["allInvitationRequests", "pendingInvitationPagination"]),
  },
  created () {
    this.$store.dispatch('fetchInvitationRequestsAction', {})
  },
  methods: {
    async pendingInvitationLoadMore() {
      await this.$store.dispatch("fetchInvitationRequestsAction", {
        loadMore: true,
      });
    },
  },
};
</script>